console.log("Cash Only");

$(".single-item").slick({
  autoplay: true,
  autoplaySpeed: 3000,
  infinite: true,
  speed: 2000,
  fade: true,
  draggable: false,
  arrows: false,
});